<template>
  <div>
    <load-spinner v-if="isLoadingSend"></load-spinner>
    <base-header base-title="Send Notif"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="'Send Notif'"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
                <button class="btn btn-sm btn-outline-primary" @click="sendNotif">
                    <i class="fas fa-paper-plane"></i> SEND
                </button>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <!-- isi -->
          <div class="row">
            <div class="col-md-8 offset-md-2 card p-3">
              <div class="row">
                <div class="col-4">Template</div>
                <div class="col-8">
                  <multiselect
                    v-model="value"
                    :options="listOptions"
                    placeholder="Search"
                    :loading="isLoading"
                    @search-change="searchWithDebounce"
                    label="templateId"
                    track-by="templateId"
                    @select="dispatchData"
                  ></multiselect>
                </div>
              </div>
              <div class="row" v-if="recipient.phoneNumber ||
                                     recipient.email ||
                                     recipient.idCareDokter 
              ">
                <div class="col-12">
                  <fieldset class="border p-2">
                    <legend class="float-none w-auto p-2">Recepient</legend>
                    <div class="row mb-2" v-if="recipient.phoneNumber">
                      <div class="col-4">Phone Number</div>
                      <div class="col-8">
                        <input type="text" class="form-control" v-model="recipient.valPhoneNumber"/>
                      </div>
                    </div>
                    <div class="row mb-2" v-if="recipient.email">
                      <div class="col-4">Email</div>
                      <div class="col-8">
                        <input type="text" class="form-control" v-model="recipient.valEmail"/>
                      </div>
                    </div>
                    <div class="row mb-2" v-if="recipient.idCareDokter">
                      <div class="col-4">Id CareDokter</div>
                      <div class="col-8">
                        <input type="text" class="form-control" v-model="recipient.valIdCareDokter"/>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <fieldset class="border p-2">
                    <legend class="float-none w-auto p-2">Params</legend>
                    <button
                      class="btn btn-outline-primary mb-2"
                      @click="addParams"
                    >
                      Add
                    </button>
                    <div
                      class="row mb-2"
                      v-for="(param, index) in params"
                      :key="index"
                    >
                      <div class="col-5">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="key"
                          v-model="params[index].key"
                        />
                      </div>
                      <div class="col-5">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="value"
                          v-model="params[index].value"
                        />
                      </div>
                      <div class="col-2">
                        <button
                          class="btn btn-danger"
                          @click="deleteParam(index)"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "./../../components/_base/BaseTitle";
import BaseHeader from "./../../components/_base/BaseHeader";
import Multiselect from "vue-multiselect";
import LoadSpinner from "./../../components/_general/LoadSpinner";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  components: {
    BaseTitle,
    BaseHeader,
    Multiselect,
    LoadSpinner
  },
  computed: {
    ...mapState("NotifikasiStore", {
      isLoading: (state) => state.isLoadingSearchTemplate,
      listOptions: (state) => state.listSearchTemplate,
      isLoadingSend: (state) => state.isLoadingSendNotif
    }),
  },
  data() {
    return {
      value: "",
      params: [],
      debounceSearch: "",
      recipient: {
        phoneNumber: false,
        valPhoneNumber: '',
        email: false,
        valEmail: '',
        idCareDokter: false,
        valIdCareDokter: ''
      },
    };
  },
  mounted() {
    this.searchById("");
  },
  methods: {
    ...mapActions({
      searchById: "NotifikasiStore/SEARCH_TEMPLATE_WITH_QUERY",
      sendNotifData: 'NotifikasiStore/SEND_NOTIF'
    }),
    ...mapMutations({
      setLoadingSearch: "NotifikasiStore/SET_LOADING_SEARCH_TEMPLATE",
    }),
    dispatchData(item) {
      this.setFalseRecipient();
      if (item.templateMethod && item.templateMethod.length > 0) {
        for (let i in item.templateMethod) {
          if (item.templateMethod[i] == "SMS" || item.templateMethod[i] == "WA")
            this.recipient.phoneNumber = true;
          else if (item.templateMethod[i] == "EMAIL")
            this.recipient.email = true;
          else if (item.templateMethod[i] == "FCM")
            this.recipient.idCareDokter = true;
        }
      }
    },
    setFalseRecipient() {
      this.recipient.phoneNumber = false;
      this.recipient.email = false;
      this.recipient.idCareDokter = false;
    },
    searchWithDebounce(query) {
      this.setLoadingSearch(true);
      if (this.debounceSearch) clearTimeout(this.debounceSearch);
      this.debounceSearch = setTimeout(() => {
        this.searchById(query);
      }, 1000);
    },
    addParams() {
      const item = {
        key: "",
        value: "",
      };
      this.params.push(item);
    },
    deleteParam(index) {
      this.params.splice(index, 1);
    },
    sendNotif() {
        const setBody = function (item) {
          let body
          try{
            body = JSON.parse(item)            
          }catch {
            body = item
          }
          return body
        }
        const params = this.params.reduce((a, v) => ({...a, [v.key]: setBody(v.value)}), {})
        const body = {
            type: this.value.templateId,
            recipient: [{
                ...(this.recipient.phoneNumber && this.recipient.valPhoneNumber ? {phone_number: this.recipient.valPhoneNumber}: {}),
                ...(this.recipient.email && this.recipient.valEmail ? {email: this.recipient.valEmail}: {}),
                ...(this.recipient.idCareDokter && this.recipient.valIdCareDokter ? {caredokter_userid: this.recipient.valIdCareDokter}: {})
            }],
            params
        }
       this.sendNotifData(body)
       setTimeout(()=> {
           this.value = {}
           this.params = []
           this.setFalseRecipient()
       }, 1000)
    }
  },
};
</script>

<style>
</style>